import React, { Component } from "react";
import { Row, Container, Col } from "reactstrap";

const DataStrategyAndManagement = (props) => {
  return (
    <React.Fragment>
      <Container>
        <Row className="api-services-content">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h4>
                  <b className="color-grey">Data Strategy and Management</b>
                </h4>
                <br />
                <p>
                  <b className="color-grey">
                    Data Assessment and Strategy Development:
                  </b>{" "}
                  Our data assessment and strategy development services begin
                  with a thorough evaluation of your current data systems. We
                  identify gaps and opportunities to enhance your data
                  utilization. Our strategic plans are designed to improve data
                  governance, enhance data quality, and streamline data
                  workflows, ensuring your data assets are managed efficiently
                  and effectively.
                </p>
                <br />
                <p>
                  <b className="color-grey">
                    Data Warehousing and Integration:
                  </b>{" "}
                  We design and implement robust data warehouses that
                  consolidate data from various modern sources, such as IoT
                  devices, social media, cloud data platforms, and traditional
                  databases, optimizing it for analysis. Our integration
                  techniques enable real-time data collection and reporting,
                  providing you with timely and accurate insights. Whether you
                  need to integrate data from on-premises systems, cloud
                  platforms, or hybrid environments, we ensure seamless data
                  flow across your organization.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DataStrategyAndManagement;
